<template>
    <div>
        <NavBar />
        <div class="comLogin short-container">
            <Breadcrumb :breadcrumb="state.listBreadcrumb" />
            <div class="comLogin__container">
                <div class="comLogin__container-title">
                    パスワード変更完了
                </div>
                <p class="comLogin__container-sub_title">
                    パスワードが変更されました。<br />
                    ログイン画面より再度ログインしてご利用ください。
                </p>
                <Form class="horizontal">
                    <div class="comLogin__container-button">
                        <button
                            type="button"
                            @click="gotoOrganizationLogin"
                            class="btn-lg sweep-to-top"
                        >
                            ログイン画面へ
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/reset-password-success/OrganizationResetPasswordSuccess.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/reset-password-success/OrganizationResetPasswordSuccess.scss";
</style>
