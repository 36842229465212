import { reactive, defineComponent } from "vue";
import { useRouter } from "vue-router";
import LanguageUtil from "@/commons/LanguageUtil";
import RoutePath from "@/commons/RoutePath";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import { useStore } from "vuex";
export default defineComponent({
    name: "CompanyResetPasswordSuccess",
    components: {
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "パスワード変更完了"
                }
            ],
            errorLoginMessage: null,
            errorLoginValidation: null,
            showSendMailForgotPassword: false,
            showForgotPassword: false
        });
        function gotoOrganizationLogin() {
            router.push(RoutePath.ORGANIZATION_LOGIN);
        }
        return {
            state: state,
            gotoOrganizationLogin: gotoOrganizationLogin
        };
    }
});
